// Types
import type React from 'react'
import type { ThemeProps } from '@outwrite/themes'
import type { LinkTypes, SpecificMapWithDefault } from '../../types'
import type { I18nextKey, BaseLocalizedProps } from './types'

// External imports
import { useRouter } from 'next/router'
import Image from '../NextImage'

import styled from '@emotion/styled'
import { css, SerializedStyles } from '@emotion/react'

// HOCs
import persistQueriesLink from '../../hocs/persist-queries-link'

// Utils
import analytics, { ClickId, DEFAULT_TARGET_CONFIG } from '../../util/analytics'
import withDefault from '../../util/with-default'

// Components
import LogoHomeLink from '../LogoHomeLink'
import LanguageSwitcher from './LanguageSwitcher'

// Constant imports
import { DEFAULT } from '../../font'
import {
  ABOUT_PATHNAME,
  BLOG_LINKS_BY_LOCALE,
  CAREERS_PATHNAME,
  CHROME_PATHNAME,
  EDGE_PATHNAME,
  FAQ_PATHNAME,
  GOOGLE_DOCS_PATHNAME,
  GRADEPROOF_PATHNAME,
  MS_WORD_PATHNAME,
  PRICING_PATHNAME,
  PRIVACY_PATHNAME,
  PRO_PATHNAME,
  SUPPORT_LINKS_BY_LOCALE,
  TABLET_WIDTH_PX,
  TEAMS_PATHNAME,
  TERMS_PATHNAME
} from '../../constants'

const WIDTH_BREAKPOINT_PX = 800
const MIN_COLS = 2

const Wrapper = styled.footer`
  max-width: 1276px;
  margin: 0 auto;
  padding: 60px 64px 80px;
  ${DEFAULT[400]}
  overflow: auto;
`

const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: ${WIDTH_BREAKPOINT_PX}px) {
    flex-direction: column;
  }
`

// LOGO SECTION

const LogoWrapper = styled.div`
  margin-right: 64px;
  margin-bottom: 64px;
  flex-shrink: 0;

  @media screen and (max-width: ${WIDTH_BREAKPOINT_PX}px) {
    margin-right: 0;
  }
`

const LogoLink = styled(LogoHomeLink)`
  margin-bottom: 32px;
  display: block;
`

const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  @media screen and (max-width: ${WIDTH_BREAKPOINT_PX}px) {
    justify-content: flex-start;
  }
`

const SocialLink = styled.a`
  margin-right: 28px;
  &:last-of-type {
    margin-right: 0;
  }
`

// TABLE OF NAV LINKS

const Nav = styled.nav`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
`

const Group = styled(Nav)`
  @media screen and (max-width: ${WIDTH_BREAKPOINT_PX}px) {
    width: 100%;
    flex-direction: column;
  }
`.withComponent('div')

const LinksWrapper = styled.div`
  width: 196px;
  padding-bottom: 32px;
  padding-right: 24px;
`

const Heading = styled.p`
  margin: 0 0 20px 0;
  line-height: 1.5;
  letter-spacing: .6px;
  font-weight: 700;
`

const List = styled.ul`
  padding-left: 0;
  list-style: none;
  margin-top: 0;
  margin-bottom: 10px;
`

const StyledLink = persistQueriesLink(
  styled.a`
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 10px;
    color: ${(props): string => props.theme.outwrite.colors.gray[900]};

    &:hover {
      text-decoration: underline;
    }
  `
)

const IconWrapper = styled.span`
  margin-right: 8px;
  height: 20px;
`

// BOTTOM OF FOOTER

const Divider = styled.hr`
  border-style: solid;
  border-color: ${(props): string => props.theme.outwrite.colors.gray[300]};
`

const BottomFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
`

const textCss = (props: ThemeProps): SerializedStyles => css`
  margin-top: 32px;
  color: ${props.theme.outwrite.colors.gray[700]};
`

const Copyright = styled.span`
  ${textCss}
  order: -1;
  @media screen and (max-width: ${TABLET_WIDTH_PX}px) {
    order: 0;
  }
`

const Text = styled.span`
  ${textCss}
  text-align: center;
  @media screen and (max-width: ${TABLET_WIDTH_PX}px) {
    width: 100%;
  }
`

const PaddedLanguageSwitcher = styled(LanguageSwitcher)`
  margin-top: 32px;
`

// Footer structure constants & types

interface BaseContent {
  readonly contentKey: I18nextKey
  readonly icon?: { src: string, alt: string }

  // for analytics
  readonly clickId?: ClickId
}

interface LinkByLocale {
  byLocale: SpecificMapWithDefault<string, LinkTypes>
}

interface NavCategory {
  titleKey: I18nextKey
  links: Array<
  (LinkTypes & BaseContent) |
  (BaseContent & LinkByLocale)
  >
}

const isLinkByLocale = (tbd: any): tbd is LinkByLocale => (
  !!(tbd as LinkByLocale).byLocale
)

const FOOTER_NAV: NavCategory[] = [
  {
    titleKey: 'footer.nav.categories.COMPANY',
    links: [
      { contentKey: 'footer.nav.links.ABOUT', href: ABOUT_PATHNAME },
      { contentKey: 'footer.nav.links.CAREERS', href: CAREERS_PATHNAME },
      { contentKey: 'footer.nav.links.BLOG', byLocale: BLOG_LINKS_BY_LOCALE },
      { contentKey: 'footer.nav.links.GRADEPROOF', href: GRADEPROOF_PATHNAME }
    ]
  },
  {
    titleKey: 'footer.nav.categories.PRODUCT',
    links: [
      { contentKey: 'footer.nav.links.PRICING', href: PRICING_PATHNAME },
      { contentKey: 'footer.nav.links.PRO', href: PRO_PATHNAME },
      { contentKey: 'footer.nav.links.TEAMS', href: TEAMS_PATHNAME }
    ]
  },
  {
    titleKey: 'footer.nav.categories.SUPPORT',
    links: [
      { contentKey: 'footer.nav.links.CONTACT', byLocale: SUPPORT_LINKS_BY_LOCALE, clickId: 'support' },
      { contentKey: 'footer.nav.links.FAQ', href: FAQ_PATHNAME },
      { contentKey: 'footer.nav.links.PRIVACY', href: PRIVACY_PATHNAME },
      { contentKey: 'footer.nav.links.TERMS', href: TERMS_PATHNAME }
    ]
  },
  {
    titleKey: 'footer.nav.categories.PLATFORMS',
    links: [
      { contentKey: 'footer.nav.links.CHROME', href: CHROME_PATHNAME, icon: { src: require('../../../public/assets/platform-logos/chrome.svg'), alt: '' } },
      { contentKey: 'footer.nav.links.EDGE', href: EDGE_PATHNAME, icon: { src: require('../../../public/assets/platform-logos/edge.png'), alt: '' } },
      { contentKey: 'footer.nav.links.MS_WORD', href: MS_WORD_PATHNAME, icon: { src: require('../../../public/assets/platform-logos/word.svg'), alt: '' } },
      { contentKey: 'footer.nav.links.GOOGLE_DOCS', href: GOOGLE_DOCS_PATHNAME, icon: { src: require('../../../public/assets/platform-logos/google-docs.svg'), alt: '' } }
    ]
  }
]

const N_ROWS = Math.ceil(FOOTER_NAV.length / MIN_COLS)

type Props = BaseLocalizedProps

const Footer: React.FC<Props> = ({ t, tOptions }) => {
  const router = useRouter()
  return (
    <Wrapper>
      <LinkContainer>
        <LogoWrapper>
          <LogoLink />
          <SocialWrapper>
            <SocialLink href='https://www.facebook.com/OutwriteAI/' aria-label='Outwrite Facebook'>
              <Image alt='Facebook' src='/assets/icons/facebook.svg' width='30px' height='30px' />
            </SocialLink>
            <SocialLink href='https://twitter.com/OutwriteAI' aria-label='Outwrite Twitter'>
              <Image alt='Twitter' src='/assets/icons/twitter.svg' width='30px' height='30px' />
            </SocialLink>
            <SocialLink href='https://www.instagram.com/outwriteai/' aria-label='Outwrite Instagram'>
              <Image alt='Instagram' src='/assets/icons/instagram.svg' width='30px' height='30px' />
            </SocialLink>
          </SocialWrapper>
        </LogoWrapper>
        <Nav>
          {/* Group links categories by row so they show up in the minimum amount of columns */}
          {Array.from({ length: N_ROWS }).map((_, i) => (
            <Group key={i}>
              {Array.from({ length: MIN_COLS }).map((_, j) => {
                const { titleKey, links } = FOOTER_NAV[i * MIN_COLS + j]
                return (
                  <LinksWrapper key={titleKey}>
                    <Heading>{t(titleKey, tOptions)}</Heading>
                    <List>
                      {links.map(({ contentKey, icon, clickId, ...rest }) => (
                        <li key={contentKey}>
                          <StyledLink
                            {...(isLinkByLocale(rest)
                              ? withDefault(rest.byLocale, router.locale)
                              : rest)}
                            onClick={
                              clickId && (
                                (): void => analytics.trackClick(
                                  clickId,
                                  router.pathname,
                                  { ...DEFAULT_TARGET_CONFIG, googleAnalytics: { event_label: 'footer' } }
                                )
                              )}
                          >
                            {icon && (
                              <IconWrapper>
                                <Image src={icon.src} alt={icon.alt} width={20} height={20} />
                              </IconWrapper>
                            )}
                            {t(contentKey, tOptions)}
                          </StyledLink>
                        </li>
                      ))}
                    </List>
                  </LinksWrapper>
                )
              })}
            </Group>
          ))}
        </Nav>
      </LinkContainer>
      <Divider />
      <BottomFooter>
        <Text>{t('footer.bottom.TEXT', tOptions)}</Text>
        <Copyright>{t('footer.bottom.COPYRIGHT', tOptions)}</Copyright>
        <PaddedLanguageSwitcher t={t} tOptions={tOptions} />
      </BottomFooter>
    </Wrapper>
  )
}

export default Footer
