// Type imports
import type React from 'react'
import type { BaseLocalizedProps } from './types'

// External imports
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import styled from '@emotion/styled'

import { SUPPORTED_LOCALES } from '../../constants'

const Select = styled.select`
  font-family: inherit;
  font-size: inherit;
  border: none;
  cursor: pointer;
  background: transparent;
`

type Props = BaseLocalizedProps

const LanguageSwitcher: React.FC<Props> = ({ t, tOptions, ...rest }) => {
  const router = useRouter()
  // TODO: make this accessible!!! add aria-s
  return (
    <Select
      {...rest}
      title={t('LANGUAGE_SWITCHER_LABEL', tOptions)}
      aria-label={t('LANGUAGE_SWITCHER_LABEL', tOptions)}
      onChange={
        (e): void => {
          // Persist language on through different sessions
          Cookies.set('NEXT_LOCALE', e.target.value, { expires: 30 })
          void router.push(
            { pathname: router.pathname, query: router.query },
            undefined,
            { locale: e.target.value }
          )
        }
      }
      defaultValue={router.locale}
    >
      {SUPPORTED_LOCALES.map(({ locale, name }) => (
        <option value={locale} key={locale}>{name}</option>
      ))}
    </Select>
  )
}

export default LanguageSwitcher
