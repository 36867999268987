import type { UrlObject } from 'url'

import Link from 'next/link'

import withTransformHref from '../hocs/with-transform-href'

const NextLink = withTransformHref(
  (href: string | UrlObject, query) => typeof href === 'string' ? ({ pathname: href, query }) : { query, ...href }
)(Link)

export default NextLink
