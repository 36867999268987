import type React from 'react'
import type { ParsedUrlQuery } from 'querystring'

import { forwardRef } from 'react'
import { useRouter } from 'next/router'

interface HrefProps<K> { href: K }

type TransformFn<K> = (href: K, query: ParsedUrlQuery) => K
type HOC<K> = <T extends HrefProps<K>>(Component: React.FC<T> | string) => React.FC<T>

// FIXME: improve typing....
const withTransformHref = <K extends any>(transformHref: TransformFn<K>): HOC<K> => {
  return (WrappedComponent): any => {
    // @ts-expect-error forwardRef undefined TODO: improve props typing
    const Transform = ({ forwardRef, ...props }): any => {
      const { query } = useRouter()
      return (
      // @ts-expect-error TODO: improve typing..
      <WrappedComponent
        ref={forwardRef}
        {...props}
        href={transformHref(props.href, query)}
      />
      )
    }
    // eslint-disable-next-line react/display-name
    return forwardRef((props, ref) => <Transform {...props} forwardRef={ref} />)
  }
}

export default withTransformHref
