import type { ImageProps } from 'next/image'

import Image from 'next/image'

import { DEFAULT_NEXT_IMG_PROPS } from '../constants'

const NextImage: React.FC<ImageProps> = (props) => (
  // eslint-disable-next-line jsx-a11y/alt-text
  <Image {...DEFAULT_NEXT_IMG_PROPS} {...props} />
)

export default NextImage
