// Type imports
import type { WithRouterProps } from 'next/dist/client/with-router'
import type { StyledComponent } from '@emotion/styled'
import type { LinkTypes } from '../types'

// External imports
import { forwardRef } from 'react'
import { withRouter } from 'next/router'

// Utils
import stringifyHrefAndQuery from '../util/stringify-href-and-query'
import { isAppLink, isExternalLink, isInternalLink } from '../util/type-guards'

// Components
import Link from 'next/link'

// Constants
import { APP_URL } from '../constants'

// FIXME: improve typing
/**
 * High-order component which transforms anchor tags to
 * accept exthref or href properties and persists any current queries
 * @param WrappedComponent Component to wrap
 * @returns A component that takes in exthref or href property
 * which persists queries and wraps any internal links with a next/link
 */
const persistQueriesLink = <T extends {}>(
  WrappedComponent: StyledComponent<React.PropsWithChildren<T>> | React.FC<T>
): React.FC<T & LinkTypes> => {
  const Persisted = withRouter(
    (
      {
        innerRef,
        router,
        ...props
      }: T & LinkTypes & WithRouterProps & { innerRef: any }
    ): JSX.Element => {
      if (isInternalLink(props)) {
        const { href, nextLinkProps, ...rest } = props
        return (
          <Link {...nextLinkProps} href={{ pathname: href, query: router.query }} passHref>
            {/* @ts-expect-error TODO: improve typing */}
            <WrappedComponent {...rest} ref={innerRef} />
          </Link>
        )
      } else if (isAppLink(props)) {
        const { apphref, ...rest } = props
        const href = stringifyHrefAndQuery(`${APP_URL}${apphref}`, router.query)
        return (
          // @ts-expect-error TODO: improve typing
          <WrappedComponent {...rest} ref={innerRef} href={href} />
        )
      } else if (isExternalLink(props)) {
        const { exthref, ...rest } = props
        const href = stringifyHrefAndQuery(exthref, router.query)
        return (
          // @ts-expect-error TODO: improve typing
          <WrappedComponent {...rest} ref={innerRef} href={href} />
        )
      } else {
        throw Error('Invalid Link Structure')
      }
    })

  // @ts-expect-error
  // eslint-disable-next-line react/display-name
  return forwardRef((props, ref) => <Persisted {...props} innerRef={ref} />)
}

export default persistQueriesLink
