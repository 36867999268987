// Type imports
import type React from 'react'
import type { ImageProps } from 'next/image'

// External imports
import Image from './NextImage'
import styled from '@emotion/styled'

// Other
import NextLink from './NextLink'

import BlackLogo from '../../public/assets/logos/black-logo-name.svg'
import Logo from '../../public/assets/logos/logo-name.svg'

import { MOBILE_WIDTH_PX } from '../constants'

const Link = styled.a`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    width: 150px;
  }
`

export interface Props {
  readonly dark?: boolean
  readonly imgProps?: Omit<ImageProps, 'src' | 'width' | 'height' | 'alt'>
}

const LogoHomeLink: React.FC<Props> = ({ dark, imgProps, ...rest }) => {
  return (
    <NextLink href='/' passHref>
      <Link {...rest}>
        <Image {...imgProps} alt='Outwrite' src={dark ? BlackLogo : Logo} width='200px' height='42px' />
      </Link>
    </NextLink>
  )
}

export default LogoHomeLink
