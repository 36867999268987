import type { ParsedUrlQuery } from 'querystring'

import querystring from 'query-string'

const stringifyHrefAndQuery = (
  url: string | undefined,
  query: ParsedUrlQuery
): string | undefined => (
  url && querystring.stringifyUrl({ url, query })
)

export default stringifyHrefAndQuery
