import type { InternalLink, ExternalLink, AppLink } from '../types'

export const isInternalLink = (tbd: any): tbd is InternalLink => (
  !!(tbd as InternalLink).href
)
export const isAppLink = (tbd: any): tbd is AppLink => (
  !!(tbd as AppLink).apphref
)
export const isExternalLink = (tbd: any): tbd is ExternalLink => (
  !!(tbd as ExternalLink).exthref
)
